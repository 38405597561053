import axios from "axios";
import "./src/scss/theme.scss";
import "./src/js/theme";

import { data } from "express-session/session/cookie";
import Region from "../models/regionModel";
import City from "../models/cityModel";
import Warehouse from "../models/warehouseModel";

import config from "../config";

const forWhom = document.querySelectorAll(".forWhom");
const paginationActive = document.querySelectorAll(".pagination-active");
const paginationActiveFirst = document.querySelector(
    ".pagination-active-first"
);
const addToCard = document.querySelectorAll(".add-to-card");
const cntProductsCart = document.querySelector(".cntProductsCart");
const lng = Cookies.get("i18next");
let sumInCartValue = document.querySelector(".sumInCart");
let sumInCartForPayValue = document.querySelector(".sumInCartForPay");
let discountInCartValue = document.querySelector(".discountInCartValue");
const removeFromCart = document.querySelectorAll(".removeFromCart");
const preloader = document.querySelector(".page-loading");
const region = document.querySelector(".region");
const citySelect = document.querySelector(".city");
const citiesInput = document.querySelector("#citiesInput");
const warehousesInput = document.querySelector("#warehousesInput");
const warehouseSelect = document.querySelector(".warehouse");
const updateAllDataPost = document.querySelector(".updateAllDataPost");
const viberShare = document.querySelector(".viberShare");
const formOnHome = document.querySelector(".form-on-home");
const alertSuccess = document.getElementById("alertSuccess");
const bntCloseAlert = document.querySelector(".bntClose");
const sendFormFromContacts = document.querySelector(".sendFormFromContacts");
const orderBtn = document.querySelector(".orderBtn");
const homeQuestionTitle = document.querySelector(".homeQuestionTitle");
const formContacts = document.querySelector(".form-contacts");
const checkTopProduct = document.querySelectorAll(".checkTopProduct");
const rangeInput = document.getElementById("range-input");
const numberInput = document.getElementById("bonucesForOrder");
const checkPromotionProduct = document.querySelectorAll(
    ".checkPromotionProduct"
);
const checkIsActiveArticles = document.querySelectorAll(
    ".checkIsActiveArticles"
);

const checkIsActiveBanner = document.querySelectorAll(".checkIsActiveBanner");

const checkIsActiveProduct = document.querySelectorAll(".checkIsActiveProduct");

const checkIsActiveReviews = document.querySelectorAll(".checkIsActiveReviews");
const checkIsActiveReviewsForProduct = document.querySelectorAll(
    ".checkIsActiveReviewsForProduct"
);
const checkIsActiveCategories = document.querySelectorAll(
    ".checkIsActiveCategories"
);

const checkIsActiveAlbums = document.querySelectorAll(".checkIsActiveAlbums");
const checkIsActiveVideos = document.querySelectorAll(".checkIsActiveVideos");

let parentElementForAddAndDel = document.querySelectorAll(
    ".parentElementForAddAndDel"
);
const addBlock = document.querySelectorAll(".addBlock");

const changeOrderStatus = document.querySelector(".changeOrderStatus");
const chooseCategoryEditOrder = document.querySelector(
    ".chooseCategoryEditOrder"
);
const chooseProductEditOrder = document.querySelector(
    ".chooseProductEditOrder"
);

const chooseCategoryForPromotion = document.querySelector(
    ".chooseCategoryForPromotion"
);
const chooseProductForPromotion = document.querySelector(
    ".chooseProductForPromotion"
);

const productsInOrder = document.querySelectorAll(".products-in-order");
const addNewProductInOrder = document.querySelector(
    ".add-new-product-in-order"
);

const addNewProductInPromotion = document.querySelector(
    ".add-new-product-in-promotion"
);
const productCnt = document.querySelectorAll(".productCnt");

const btnDelProductFromOrder = document.querySelectorAll(
    ".btnDelProductFromOrder"
);

const btnDelProductFromPromotion = document.querySelectorAll(
    ".btnDelProductFromOrder"
);

const removePhoto = document.querySelectorAll(".removePhoto");

const listProductsForOrder = document.querySelector(".listProductsForOrder");
const listProductsForPromotion = document.querySelector(
    ".listProductsForPromotion"
);

const formProductsInOrder = document.querySelector(".formProductsInOrder");

const sumOrder = document.querySelector(".sumOrder");

const cntInputProduct = document.querySelectorAll(".cntInputProduct");

const btnUpdateOrder = document.querySelector(".btnUpdateOrder");

const btnSignupUser = document.querySelector(".btnSignupUser");

const btnLoginUser = document.querySelector(".loginUser");

const btnEditUserDataPrivateOffice = document.querySelector(
    ".btnEditUserDataPrivateOffice"
);

// Edit order
// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

let oldListProducts = [];

if (productsInOrder.length > 0) {
    productsInOrder.forEach((productInOrder) => {
        const productTitle = productInOrder.querySelector(".productTitle");
        const productCnt = productInOrder.querySelector(".productCnt");

        oldListProducts.push({
            productTitle: productTitle.textContent,
            productCnt: productCnt.value * 1,
        });
    });

    formProductsInOrder.addEventListener("change", (el) => {
        const changeCntProductsInOrder = el.target;
        if (changeCntProductsInOrder.classList.contains("productCnt")) {
            const parentChangeCntProductsInOrder =
                changeCntProductsInOrder.parentNode.parentNode;
            const price =
                parentChangeCntProductsInOrder.querySelector(".price");
            parentChangeCntProductsInOrder.querySelector(
                ".sumPrice"
            ).textContent = price.textContent * changeCntProductsInOrder.value;
            getSumOrder();
        }
    });

    formProductsInOrder.addEventListener("click", (el) => {
        const delElement = el.target;
        if (delElement.classList.contains("btnDelProductFromOrder")) {
            const newProduct = {
                newProduct: delElement.id.split("-")[1],
            };
            axios
                .post(`${config.url}/admin/admin-get-product`, newProduct)
                .then((data) => {
                    const getProduct = data.data.product;

                    const search = (product) =>
                        product.productTitle === getProduct.translate.ru.title;

                    const index = oldListProducts.findIndex(search);
                    oldListProducts.splice(index, 1);
                    // oldListProducts = oldListProducts;
                });

            delElement.closest(".products-in-order").remove();
            getSumOrder();
        }
    });
}
if (productsInOrder.length > 0) {
    productsInOrder.forEach((productInOrder) => {
        const productTitle = productInOrder.querySelector(".productTitle");
        const productCnt = productInOrder.querySelector(".productCnt");

        oldListProducts.push({
            productTitle: productTitle.textContent,
            productCnt: productCnt.value * 1,
        });
    });

    formProductsInOrder.addEventListener("change", (el) => {
        const changeCntProductsInOrder = el.target;
        if (changeCntProductsInOrder.classList.contains("productCnt")) {
            const parentChangeCntProductsInOrder =
                changeCntProductsInOrder.parentNode.parentNode;
            const price =
                parentChangeCntProductsInOrder.querySelector(".price");
            parentChangeCntProductsInOrder.querySelector(
                ".sumPrice"
            ).textContent = price.textContent * changeCntProductsInOrder.value;
            getSumOrder();
        }
    });

    formProductsInOrder.addEventListener("click", (el) => {
        const delElement = el.target;
        if (delElement.classList.contains("btnDelProductFromOrder")) {
            const newProduct = {
                newProduct: delElement.id.split("-")[1],
            };
            axios
                .post(`${config.url}/admin/admin-get-product`, newProduct)
                .then((data) => {
                    const getProduct = data.data.product;

                    const search = (product) =>
                        product.productTitle === getProduct.translate.ru.title;

                    const index = oldListProducts.findIndex(search);
                    oldListProducts.splice(index, 1);
                    // oldListProducts = oldListProducts;
                });

            delElement.closest(".products-in-order").remove();
            getSumOrder();
        }
    });
}

let selectCategoryForPromotion = document.querySelectorAll(
    ".selectCategoryForPromotion"
);

let selectProductForPromotion = document.querySelector(
    ".selectProductForPromotion"
);

let parentElementForAddAndDelPromotion = document.querySelectorAll(
    ".parentElementForAddAndDel"
);

if (selectCategoryForPromotion.length > 0) {
    parentElementForAddAndDelPromotion.forEach((element) => {
        element.addEventListener("change", (event) => {
            const select = event.target;

            if (select.classList.contains("selectCategoryForPromotion")) {
                const category = {
                    category: event.target.value,
                };

                selectProductForPromotion = select.parentNode.querySelector(
                    ".selectProductForPromotion"
                );

                // if (selectCategoryForPromotion.value)
                //     // selectCategoryForPromotion.value = null;
                const length = selectProductForPromotion.options.length;

                for (let i = length - 1; i >= 0; i--) {
                    selectProductForPromotion.options[i].remove();
                }

                axios
                    .post(
                        `${config.url}/admin/admin-get-products-from-title-category`,
                        category
                    )
                    .then((data) => {
                        const products = data.data.products;

                        for (let i = 0; i < products.length; i++) {
                            const option = document.createElement("option");
                            option.value = products[i]["translate"].ru.title;
                            option.textContent = `${products[i]["translate"].ru.title}`;
                            selectProductForPromotion.appendChild(option);
                        }
                    });
            }
        });
    });
}

const getSumOrder = () => {
    const sumPrice = document.querySelectorAll(".sumPrice");
    let newSumOrder = 0;
    sumPrice.forEach((sum) => {
        newSumOrder += sum.textContent * 1;
    });
    sumOrder.textContent = newSumOrder;
};

if (addNewProductInOrder) {
    addNewProductInOrder.addEventListener("click", () => {
        preloader.classList.add("active");
        if (chooseCategoryEditOrder.value && chooseProductEditOrder.value) {
            const newProduct = {
                newProduct:
                    chooseProductEditOrder.options[
                        chooseProductEditOrder.selectedIndex
                    ].getAttribute("data-id"),
            };
            axios
                .post(`${config.url}/admin/admin-get-product`, newProduct)
                .then((data) => {
                    const product = data.data.product;

                    const duplicateAddingProducts = oldListProducts.filter(
                        (product) => {
                            return (
                                product.productTitle ===
                                chooseProductEditOrder.value
                            );
                        }
                    );

                    const dataNewProdForList = {
                        productTitle: product.translate.ru.title,
                        productCnt: 1,
                    };

                    if (duplicateAddingProducts.length === 0) {
                        oldListProducts = [
                            ...oldListProducts,
                            dataNewProdForList,
                        ];

                        const idProduct = product._id;

                        listProductsForOrder.insertAdjacentHTML(
                            "beforeend",
                            `<div class="input-group row stringForProducts products-in-order">
                                    <div class="col-sm-2 input-group-text"><span class="vendorCode">${
                                        product.vendorCode
                                    }</span></div>
                                    <div class="col-sm-4 input-group-text"><span class="productTitle">${
                                        product.translate.ru.title
                                    }</span></div>
                                    <div class="col-sm-1 input-group-text"><input id='productCnt-${idProduct}' class="form-control productCnt" type="number" value='1' min="1" step="1" required="required" autocomplete="off"/></div>
                                    <div class="col-sm-2 input-group-text"><span class="price">${
                                        product.discountPrice
                                            ? product.discountPrice
                                            : product.price
                                    }</span></div>
                                    <div class="col-sm-2 input-group-text"><span class="sumPrice">${
                                        product.discountPrice
                                            ? product.discountPrice
                                            : product.price
                                    }</span></div>
                                    <div class="col-sm-1 input-group-text">
                                        <a class="btn bg-white btn-icon marginProductFromListInOrder btnDelProductFromOrder">
                                          <i id="btnDelProduct-${idProduct}" class="ai-trash-2 text-danger btnDelProductFromOrder"></i>
                                        </a>
                                    </div>
                                 </div>`
                        );
                        getSumOrder();
                        preloader.remove();
                    }

                    if (duplicateAddingProducts.length > 0) {
                        const index = oldListProducts.findIndex(
                            (element, index) => {
                                if (
                                    element.productTitle ===
                                    chooseProductEditOrder.value
                                ) {
                                    return true;
                                }
                            }
                        );

                        const idCnt = `productCnt-${product._id}`;

                        oldListProducts[index].productCnt++;
                        const productCnt = document.getElementById(idCnt);
                        productCnt.value++;

                        const dataElementProduct =
                            productCnt.parentNode.parentNode;

                        const price =
                            dataElementProduct.querySelector(
                                ".price"
                            ).textContent;
                        const cnt =
                            dataElementProduct.querySelector(".productCnt");

                        dataElementProduct.querySelector(
                            ".sumPrice"
                        ).textContent = cnt.value * price;

                        getSumOrder();
                        preloader.remove();
                    }
                });
        }
    });
}

if (addNewProductInPromotion) {
    addNewProductInPromotion.addEventListener("click", () => {
        preloader.classList.add("active");
        if (
            chooseCategoryForPromotion.value &&
            chooseProductForPromotion.value
        ) {
            const newProduct = {
                newProduct:
                    chooseProductForPromotion.options[
                        chooseProductForPromotion.selectedIndex
                    ].getAttribute("data-id"),
            };
            axios
                .post(`${config.url}/admin/admin-get-product`, newProduct)
                .then((data) => {
                    const product = data.data.product;

                    const duplicateAddingProducts = oldListProducts.filter(
                        (product) => {
                            return (
                                product.productTitle ===
                                chooseProductForPromotion.value
                            );
                        }
                    );

                    const dataNewProdForList = {
                        productTitle: product.translate.ru.title,
                    };

                    if (duplicateAddingProducts.length === 0) {
                        oldListProducts = [
                            ...oldListProducts,
                            dataNewProdForList,
                        ];

                        const idProduct = product._id;

                        listProductsForPromotion.insertAdjacentHTML(
                            "beforeend",
                            `<div class="input-group row stringForProducts products-in-order">
                                        <div class="col-sm-2 input-group-text">
                                          <input class="vendorCode" type="text" id="vendorCodefweffwe" name="vendorCodefweffwe" value=${product.vendorCode} disabled />
                                        </div>
                                        <div class="col-sm-4 input-group-text"><span class="productTitle">${product.translate.ru.title}</span></div>
                                        <div class="col-sm-2 input-group-text"><span class="price">${product.price}</span></div>
                                        <div class="col-sm-2 input-group-text"><span class="sumPrice">${product.price}</span></div>
                                        <div class="col-sm-1 input-group-text">
                                            <a class="btn bg-white btn-icon marginProductFromListInOrder btnDelProductFromOrder">
                                              <i id="btnDelProduct-${idProduct}" class="ai-trash-2 text-danger btnDelProductFromOrder"></i>
                                            </a>
                                        </div>
                                     </div>`
                        );
                        preloader.remove();
                    }

                    if (duplicateAddingProducts.length > 0) {
                        preloader.remove();
                    }
                });
        }
    });
}

if (chooseCategoryEditOrder) {
    chooseCategoryEditOrder.addEventListener("change", () => {
        const selectCategory = {
            selectCategory:
                chooseCategoryEditOrder.options[
                    chooseCategoryEditOrder.selectedIndex
                ].getAttribute("data-id"),
        };

        if (chooseProductEditOrder.value) chooseProductEditOrder.value = null;

        const length = chooseProductEditOrder.options.length;
        for (let i = length - 1; i >= 0; i--) {
            chooseProductEditOrder.options[i].remove();
        }
        axios
            .post(`${config.url}/admin/admin-get-products`, selectCategory)
            .then((data) => {
                const products = data.data.products;
                for (let i = 0; i < products.length; i++) {
                    const select = document.querySelector(
                        ".chooseProductEditOrder"
                    );
                    const option = document.createElement("option");
                    option.dataset.id = products[i]._id;
                    option.value = products[i]["translate"]["ru"].title;
                    option.text = products[i]["translate"]["ru"].title;
                    select.appendChild(option);
                }
            });
    });
}

if (chooseCategoryForPromotion) {
    chooseCategoryForPromotion.addEventListener("change", () => {
        const selectCategory = {
            selectCategory:
                chooseCategoryForPromotion.options[
                    chooseCategoryForPromotion.selectedIndex
                ].getAttribute("data-id"),
        };

        if (chooseProductForPromotion.value)
            chooseProductForPromotion.value = null;

        const length = chooseProductForPromotion.options.length;
        for (let i = length - 1; i >= 0; i--) {
            chooseProductForPromotion.options[i].remove();
        }
        axios
            .post(`${config.url}/admin/admin-get-products`, selectCategory)
            .then((data) => {
                const products = data.data.products;
                for (let i = 0; i < products.length; i++) {
                    const select = document.querySelector(
                        ".chooseProductForPromotion"
                    );
                    const option = document.createElement("option");
                    option.dataset.id = products[i]._id;
                    option.value = products[i]["translate"]["ru"].title;
                    option.text = products[i]["translate"]["ru"].title;
                    select.appendChild(option);
                }
            });
    });
}

if (changeOrderStatus) {
    changeOrderStatus.addEventListener("change", () => {
        let parent = changeOrderStatus.closest(".formForPreloader");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforeend", preloader);

        const changeOrder = {
            idOrder: changeOrderStatus.dataset.id,
            orderStatus: changeOrderStatus.value,
        };
        axios.patch(`/admin/change-order-status`, changeOrder).then((data) => {
            if (data) {
                preloader.remove();
            }
        });
    });
}

if (btnUpdateOrder) {
    if (numberInput && rangeInput) {
        // Обновление number при изменении значения в range
        rangeInput.addEventListener("input", () => {
            // Устанавливаем значение number
            numberInput.value = rangeInput.value;
        });
    }
    btnUpdateOrder.addEventListener("click", (e) => {
        e.preventDefault();
        const regionDelivery = document.querySelector(".regionDelivery");
        const cityDelivery = document.querySelector(".cityDelivery");
        const warehouseDelivery = document.querySelector(".warehouseDelivery");
        const phoneUser = document.querySelector(".phoneUser");
        const nameUser = document.querySelector(".nameUser");
        const fullNameUser = document.querySelector(".fullNameUser");
        const paymentType = document.querySelector(".paymentType");
        const orderID = changeOrderStatus.dataset.id;
        const orderStatus = changeOrderStatus.value;
        const productCnt = document.querySelectorAll(".productCnt");
        const products = [];
        const sumOrder = document.querySelector(".sumOrder");
        const bonuses =
            document.querySelector(".bonucesForOrder").value * 1 || 0;

        if (sumOrder.textContent / 2 >= bonuses) {
            for (let i = 0; i < productCnt.length; i++) {
                products.push({
                    productId: productCnt[i].id.split("-")[1],
                    productCnt: productCnt[i].value * 1,
                });
            }

            const data = {
                products: products,
                orderID: orderID,
                orderStatus: orderStatus,
                regionDelivery: regionDelivery.value,
                cityDelivery: cityDelivery.value,
                warehouseDelivery: warehouseDelivery.value,
                phoneUser: phoneUser.value,
                nameUser: nameUser.value,
                fullNameUser: fullNameUser.value,
                paymentType: paymentType.value,
                sumOrder: sumOrder.value,
                bonuses: bonuses,
            };
            let parent = changeOrderStatus.closest(".formForPreloader");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            axios.patch(`/admin/admin-update-order`, data).then((data) => {
                if (data) {
                    window.location.href = `${config.url}/admin/admin-get-orders`;
                }
            });
        } else {
            // Находим элемент с классом .bonucesForOrder
            const bonucesElement = document.querySelector(".bonucesForOrder");

            if (bonucesElement) {
                // Добавляем класс .alert-danger
                bonucesElement.classList.add("alert-danger");
            }
        }
    });
}

// Add and remove elements for create and edd articles and products
// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

if (parentElementForAddAndDel.length > 0) {
    parentElementForAddAndDel.forEach((element) => {
        element.addEventListener("click", (el) => {
            const delElement = el.target;
            let parent = element.closest(".parentElementForAddAndDel");
            let newBlock = parent.querySelectorAll(".newBlock");
            if (
                delElement.classList.contains("delElementBtn") &&
                newBlock.length > 1
            ) {
                delElement.closest(".newBlock").remove();
            }
        });
    });
}

if (addBlock) {
    addBlock.forEach((element) => {
        element.addEventListener("click", () => {
            parentElementForAddAndDel = document.querySelectorAll(
                ".parentElementForAddAndDel"
            );

            let parent = element.closest(".parentElementForAddAndDel");
            let newBlock = parent.querySelector(".newBlock");
            const clone = newBlock.cloneNode(true);

            const selectCategoryForPromotionNewBlock = clone.querySelector(
                ".selectCategoryForPromotion"
            );

            const selectProductForPromotionNewBlock = clone.querySelector(
                ".selectProductForPromotion"
            );

            if (selectCategoryForPromotionNewBlock) {
                selectCategoryForPromotionNewBlock.value = "";
            }

            if (selectProductForPromotionNewBlock) {
                selectProductForPromotionNewBlock.value = "";
            }

            element.insertAdjacentElement("afterend", clone);

            selectCategoryForPromotion = document.querySelectorAll(
                ".selectCategoryForPromotion"
            );

            parentElementForAddAndDelPromotion = document.querySelectorAll(
                ".parentElementForAddAndDel"
            );
        });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

const markdownArticlesRU = document.querySelector(".form-control-markdown-RU");
const markdownArticlesUA = document.querySelector(".form-control-markdown-UA");

const markdownProductsRU = document.querySelector(
    ".form-control-markdown-products-RU"
);
const markdownProductsUA = document.querySelector(
    ".form-control-markdown-products-UA"
);

const markdownProductsSeoRU = document.querySelector(
    ".form-control-markdown-products-seo-RU"
);
const markdownProductsSeoUA = document.querySelector(
    ".form-control-markdown-products-seo-UA"
);
const blockquote = document.querySelector("blockquote");

if (blockquote) {
    blockquote.classList.add("blockquote", "mb-0");
    const oldParent = blockquote.parentNode;
    let parent = document.createElement("div");
    parent.classList.add("p-md-3", "new-Element-for-quote");
    oldParent.replaceChild(parent, blockquote);
    parent.appendChild(blockquote);

    const newElement = document.querySelector(".new-Element-for-quote");
    const oldParentNewElement = newElement.parentNode;
    let parentNewElement = document.createElement("div");
    parentNewElement.classList.add(
        "bg-faded-primary",
        "rounded-3",
        "p-4",
        "mb-4"
    );
    oldParentNewElement.replaceChild(parentNewElement, newElement);
    parentNewElement.appendChild(newElement);
}

const listForArticleUl = document.querySelectorAll("ul");
const listForArticleLi = document.querySelectorAll("li");
const isArticle = document.querySelector(".for-article");

if (listForArticleLi && isArticle) {
    listForArticleUl.forEach((el) => {
        if (el.closest(".for-article")) {
            el.classList.add("list-unstyled", "py-2", "mb-4");
        }
    });
    listForArticleLi.forEach((el) => {
        if (el.closest(".for-article")) {
            const text = el.textContent;
            el.classList.add("align-items-center", "mb-3");
            el.insertAdjacentHTML(
                "afterbegin",
                `<i class="ai-check fs-xl text-primary me-2"></i>`
            );
        }
    });
}

if (markdownArticlesUA || markdownArticlesRU) {
    const easyMDEUA = new EasyMDE({
        element: document.getElementById("description-article-UA"),
    });

    const easyMDERU = new EasyMDE({
        element: document.getElementById("description-article-RU"),
    });
}

if (markdownProductsRU || markdownProductsUA) {
    const easyMDEUA = new EasyMDE({
        element: document.getElementById("shortDescriptionUA"),
    });

    const easyMDERU = new EasyMDE({
        element: document.getElementById("shortDescriptionRU"),
    });
}

if (markdownProductsSeoRU || markdownProductsSeoUA) {
    const easyMDEUA = new EasyMDE({
        element: document.getElementById("seoTextForProductUA"),
    });

    const easyMDERU = new EasyMDE({
        element: document.getElementById("seoTextForProductRU"),
    });
}

if (checkIsActiveArticles.length > 0) {
    checkIsActiveArticles.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeArticle = {
                idArticle: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/update-is-active-articles`, changeArticle)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveBanner.length > 0) {
    checkIsActiveBanner.forEach((banner) => {
        banner.addEventListener("change", () => {
            const isCheck = banner.checked;
            let parent = banner.closest(".content-block");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);
            const changeBanner = {
                id: banner.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/update-is-active-banner`, changeBanner)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveReviews.length > 0) {
    checkIsActiveReviews.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeReview = {
                idReview: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/update-is-active-review`, changeReview)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveReviewsForProduct.length > 0) {
    checkIsActiveReviewsForProduct.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeReview = {
                idReview: el.dataset.id,
                isCheck,
            };
            axios
                .patch(
                    `/admin/update-is-active-review-for-product`,
                    changeReview
                )
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveAlbums.length > 0) {
    checkIsActiveAlbums.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeAlbum = {
                idAlbum: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/update-is-active-albums`, changeAlbum)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveVideos.length > 0) {
    checkIsActiveVideos.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeVideo = {
                idVideo: el.dataset.id,
                isCheck,
            };

            axios
                .patch(`/admin/update-is-active-video`, changeVideo)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveCategories.length > 0) {
    checkIsActiveCategories.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".form-check");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeCategory = {
                idCategory: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/update-is-active-categories`, changeCategory)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkTopProduct.length > 0) {
    checkTopProduct.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".content-block");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeProduct = {
                idProduct: el.dataset.id,
                isCheck,
            };
            axios.patch(`/admin/updateIsTop`, changeProduct).then((data) => {
                if (data) {
                    preloader.remove();
                }
            });
        });
    });
}

const isActivePromotions = document.querySelectorAll(".isActivePromotions");

if (isActivePromotions.length > 0) {
    isActivePromotions.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".content-block");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeProduct = {
                idPromotion: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/updateIsActivePromotion`, changeProduct)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkPromotionProduct.length > 0) {
    checkPromotionProduct.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".content-block");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeProduct = {
                idProduct: el.dataset.id,
                isCheck,
            };
            axios
                .patch(`/admin/updateIsPromotionProducts`, changeProduct)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

if (checkIsActiveProduct.length > 0) {
    checkIsActiveProduct.forEach((el) => {
        el.addEventListener("change", () => {
            const isCheck = el.checked;

            let parent = el.closest(".content-block");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            const changeProduct = {
                idProduct: el.dataset.id,
                isCheck,
            };
            axios.patch(`/admin/updateIsActive`, changeProduct).then((data) => {
                if (data) {
                    preloader.remove();
                }
            });
        });
    });
}

const checkIsMailerUser = document.querySelector(".checkIsMailerUser");

if (checkIsMailerUser) {
    checkIsMailerUser.addEventListener("change", () => {
        const isCheck = checkIsMailerUser.checked;

        let parent = checkIsMailerUser.closest(".content-block");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforeend", preloader);

        const changeUser = {
            isCheck: isCheck,
        };

        axios.patch(`/updateIsMailer`, changeUser).then((data) => {
            if (data) {
                preloader.remove();
            }
        });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

// Order
const orderStatus = document.querySelectorAll(".orderStatus");

if (orderStatus.length > 0) {
    orderStatus.forEach((status) => {
        if (status.textContent === "Отправлен") {
            status.classList.add("bg-faded-success", "text-success");
        } else if (status.textContent === "Отменён") {
            status.classList.add("bg-faded-danger", "text-danger");
        } else {
            status.classList.add("bg-faded-info", "text-info");
        }
    });
}

if (orderBtn) {
    orderBtn.addEventListener("click", (e) => {
        e.preventDefault();

        orderBtn.disabled = true;
        let parent = document.querySelector(".formCreateOrder");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforeend", preloader);

        const formOrderName = document.querySelector(".formOrderName");
        const formOrderFullName = document.querySelector(".formOrderFullName");
        const formOrderRegion = document.querySelector(".formOrderRegion");
        const formOrderCity = document.querySelector(".formOrderCity");
        const formOrderWarehouse = document.querySelector(
            ".formOrderWarehouse"
        );
        const formOrderPhoneUser = document.querySelector(
            ".formOrderPhoneUser"
        );
        const formOrderEmailUser = document.querySelector(
            ".formOrderEmailUser"
        );
        const formPaymentMethod = document.querySelector(".formPaymentMethod");
        const formOrderComments = document.querySelector(".formOrderComments");
        const bonucesForOrder = document.querySelector(".bonucesForOrder");

        removeErrors();

        const createConfirmation = document.querySelector(
            "#createConfirmation"
        );

        axios
            .post(`${config.url}/${lng}/createOrder`, {
                nameUser: formOrderName.value,
                fullNameUser: formOrderFullName.value,
                regionUser: formOrderRegion.value,
                cityUser: formOrderCity.value,
                warehouseUser: formOrderWarehouse.value,
                phoneUser: formOrderPhoneUser.value,
                emailUser: formOrderEmailUser.value,
                paymentMethod: formPaymentMethod.value,
                messageUser: formOrderComments.value,
                bonucesForOrder: bonucesForOrder?.value || 0,
                createConfirmation: createConfirmation?.checked,
            })
            .then((res) => {
                preloader.remove();
                if (
                    res.status === 201 &&
                    res.data.data.order.paymentType !== "Оплата на карту"
                ) {
                    window.location.href = `${config.url}/${lng}/success/${res.data.data.order.orderNumber}`;
                }
                if (
                    res.status === 201 &&
                    res.data.data.order.paymentType === "Оплата на карту"
                ) {
                    const form = res.data.data.html;
                    document
                        .querySelector("body")
                        .insertAdjacentHTML("beforebegin", form);

                    const liqPayForm = document.querySelector("#liqpayForm");
                    liqPayForm.submit();
                }
            })
            .catch((error) => {
                preloader.remove();
                if (!error.response.data.errors) {
                    const err = `<p class="error-massage-global">
                                        * ${i18next.t("global error")}
                                </p>`;
                    orderBtn.insertAdjacentHTML("afterend", err);
                } else {
                    const errors = error.response.data.errors.errors;

                    removeErrors();

                    errors.forEach((err) => {
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">
                                            * ${i18next.t(err.msg)}
                                       </p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                        orderBtn.disabled = false;
                    });
                }
            });
    });
}
// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

// Translate ERRORS

import i18next from "i18next";
import { integer } from "sharp/lib/is";

i18next.init({
    lng: lng,
    resources: {
        ua: {
            translation: {
                "name form":
                    "Ім'я обов'язкове для заповнення не менше 3-х і не більше 30 символів",
                "email form": "Email має бути заповнений коректно",
                "text form":
                    "Це поле обов'язково для заповнення. (не більше 2000 символів)",
                "phone form": "Номер телефону має бути заповнений коректно",
                "company form": "Вкажіть, будь ласка, назву Вашої компанії",
                "paymentMethod form": "Вкажіть метод оплати",
                "global error": "Введено неправильні дані",
                "rating form": "Не коректні данні",
                "text review form": "Це поле обов'язково для заповнення",
                "User exist":
                    "Куристувач з данною електронною адресою зареєстрований",
                "The passwords do not match":
                    "Введені Вами паролі не співпадають",
                "Email exist": "Данний Email вже зареєстрований",
                "Email or password entered incorrectly":
                    "Email чи пароль введені не вірно",
                "First name no correct": "Ім'я заповнено не коректно",
                "Last name no correct": "Прізвище заповнено не коректно",
                "Phone number exist": "Такий номер вже зареєстровано",
            },
        },
        ru: {
            translation: {
                "name form":
                    "Имя обязательно для заполнения, не менее 3-х и не более 30 символов",
                "email form": "Email должен быть заполнен корректно",
                "text form":
                    "Данное поле обязательно для заполнения. (не более 2000 символов)",
                "phone form": "Номер телефона должен быть заполнен корректно",
                "company form": "Укажите пожалуйста название Вашей компании",
                "paymentMethod form": "Укажите метод оплаты",
                "global error": "Введены неверные данные",
                "rating form": "Не коректные данные",
                "text review form": "Данное поле обязательно для заполнения",
                "User exist":
                    "Пользователь с таким электронным адресом уже зарегистрирован",
                "The passwords do not match":
                    "Введённые Вами пароли не совпадают",
                "Email exist": "Данный Email уже зарегистрирован",
                "Email or password entered incorrectly":
                    "Email или пароль введены не верно",
                "First name no correct": "Имя заполнено не коректно",
                "Last name no correct": "Фамилия заполнена не коректно",
                "Phone number exist": "Даный номер уже зарегистрирован",
            },
        },
    },
});

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

// SEND EMAIL
if (bntCloseAlert) {
    bntCloseAlert.addEventListener("click", (e) => {
        e.preventDefault();
        alertSuccess.style.display = "none";
    });
}

const removeErrors = () => {
    const errorsMassages = document.querySelectorAll(".validateForm");
    if (errorsMassages.length > 0) {
        for (let i = 0; i < errorsMassages.length; i++) {
            errorsMassages[i].classList.remove("validateForm");
        }
    }

    const errMassage = document.querySelectorAll(".error-massage");
    const errMassageGlobal = document.querySelectorAll(".error-massage-global");

    if (errMassage.length > 0) {
        for (let i = 0; i < errMassage.length; i++) {
            errMassage[i].remove();
        }
    }
    if (errMassageGlobal.length > 0) {
        for (let i = 0; i < errMassageGlobal.length; i++) {
            errMassageGlobal[i].remove();
        }
    }
};

if (sendFormFromContacts) {
    sendFormFromContacts.addEventListener("click", (e) => {
        e.preventDefault();
        const formOnContactsName = document.querySelector(
            ".formOnContactsName"
        );
        const formOnContactsEmail = document.querySelector(
            ".formOnContactsEmail"
        );
        const formOnContactsPhone = document.querySelector(
            ".formOnContactsPhone"
        );
        const formOnContactsCompany = document.querySelector(
            ".formOnContactsCompany"
        );
        const formOnContactsMessage = document.querySelector(
            ".formOnContactsMessage"
        );
        removeErrors();

        axios
            .post(`${config.url}/${lng}/send-mail-contacts`, {
                nameUs: formOnContactsName.value,
                emailUser: formOnContactsEmail.value,
                phoneUser: formOnContactsPhone.value,
                companyUser: formOnContactsCompany.value,
                messageUser: formOnContactsMessage.value,
            })
            .then((res) => {
                if (res.data) {
                    alertSuccess.style.display = "block";
                    formOnContactsName.value = "";
                    formOnContactsEmail.value = "";
                    formOnContactsPhone.value = "";
                    formOnContactsCompany.value = "";
                    formOnContactsMessage.value = "";
                    removeErrors();
                }
            })
            .catch((error) => {
                if (!error.response.data.errors) {
                    const err = `<p class="error-massage-global">
                                        * ${i18next.t("global error")}
                                </p>`;

                    formContacts.insertAdjacentHTML("afterend", err);
                } else {
                    const errors = error.response.data.errors.errors;

                    removeErrors();

                    errors.forEach((err) => {
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">
                                        * ${i18next.t(err.msg)}
                                   </p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                    });
                }
            });
    });
}

if (formOnHome) {
    formOnHome.addEventListener("click", (e) => {
        e.preventDefault();
        const formOnHomeName = document.querySelector(".formOnHomeName");
        const formOnHomeEmail = document.querySelector(".formOnHomeEmail");
        const formOnHomeMessage = document.querySelector(".formOnHomeMessage");

        removeErrors();

        axios
            .post(`${config.url}/${lng}/send-mail-home`, {
                nameUs: formOnHomeName.value,
                emailUser: formOnHomeEmail.value,
                messageUser: formOnHomeMessage.value,
            })
            .then((res) => {
                if (res.data) {
                    alertSuccess.style.display = "block";
                    formOnHomeName.value = "";
                    formOnHomeEmail.value = "";
                    formOnHomeMessage.value = "";
                    removeErrors();
                }
            })
            .catch((error) => {
                if (!error.response.data.errors) {
                    const err = `<p class="error-massage-global">
                                        * ${i18next.t("global error")}
                                </p>`;

                    homeQuestionTitle.insertAdjacentHTML("afterend", err);
                } else {
                    removeErrors();
                    const errors = error.response.data.errors.errors;
                    errors.forEach((err) => {
                        console.log(err);
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">
                                        * ${i18next.t(err.msg)}
                                   </p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                    });
                }
            });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

// UPDATE NEW POST
if (updateAllDataPost) {
    updateAllDataPost.addEventListener("click", (e) => {
        e.preventDefault();
        const post = {};

        let parent = document.querySelector(".contentBlock");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforeend", preloader);

        axios
            .post(`${config.url}/admin/updateAllDataPost`, {
                post,
            })
            .then((res) => {
                if (res) {
                    preloader.remove();
                    alertSuccess.style.display = "block";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

// GET SHARING

if (viberShare) {
    const buttonID = "viber_share";
    const text = "LeVi: HAPPY PET – HAPPY PERSON: ";
    document
        .getElementById(buttonID)
        .setAttribute(
            "href",
            "viber://forward?text=" +
                encodeURIComponent(text + " " + window.location.href)
        );
}

// ***************************************************************************************************************

// GET CITIES

if (region) {
    $("#region").on("select2:select", function (e) {
        let parent = document.querySelector(".warehouseSelect");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforebegin", preloader);

        const selectRegion = {
            selectRegion:
                region.options[region.selectedIndex].getAttribute("data-id"),
        };

        if (citiesInput.value) citiesInput.value = null;
        if (warehousesInput.value) warehousesInput.value = null;

        const length = citySelect.options.length;
        for (let i = length - 1; i >= 0; i--) {
            citySelect.options[i].remove();
        }

        axios
            .post(`${config.url}/${lng}/getCities`, selectRegion)
            .then((data) => {
                const cities = data.data.cities;
                const select = document.querySelector(".selectCity");
                // const option = document.createElement("option");

                for (let i = 0; i < cities.length; i++) {
                    const option = document.createElement("option");
                    option.dataset.id = cities[i]._id;
                    option.dataset.test =
                        cities[i].Description + "/" + cities[i].DescriptionRu;

                    if (lng === "ua") {
                        option.value = cities[i].Description;
                        option.textContent = cities[i].Description;
                    }
                    if (lng === "ru") {
                        option.value = cities[i].DescriptionRu;
                        option.textContent = cities[i].DescriptionRu;
                    }
                    select.appendChild(option);
                }
                $(".selectCity").select2({
                    matcher: function (params, data) {
                        if ($.trim(params.term) === "") {
                            return data;
                        }

                        if (typeof data.text === "undefined") {
                            return null;
                        }

                        if (
                            data.element.dataset.test
                                .toLowerCase()
                                .indexOf(params.term.toLowerCase()) > -1
                        ) {
                            const modifiedData = $.extend({}, data, true);

                            return modifiedData;
                        }

                        return null;
                    },
                    sorter: function (result) {
                        const query = $(".select2-search__field")
                            .val()
                            .toLowerCase();

                        return result.sort(function (a, b) {
                            return (
                                a.element.attributes
                                    .getNamedItem("data-test")
                                    .textContent.toLowerCase()
                                    .indexOf(query) -
                                b.element.attributes
                                    .getNamedItem("data-test")
                                    .textContent.toLowerCase()
                                    .indexOf(query)
                            );
                        });
                    },
                });
                preloader.remove();
            });
    });
}

if (citiesInput) {
    $("#citiesInput").on("select2:select", function (e) {
        let selectCity;
        citySelect.options.forEach((el) => {
            if (el.value === citiesInput.value) {
                selectCity = {
                    selectCity: el.getAttribute("data-id"),
                };
                citiesInput.setAttribute("value", el.value);
            }
        });

        let parent = document.querySelector(".warehouseSelect");
        preloader.classList.add("active");
        parent.insertAdjacentElement("beforebegin", preloader);

        if (warehousesInput.value) warehousesInput.value = null;

        const length = warehouseSelect.options.length;
        for (let i = length - 1; i >= 0; i--) {
            warehouseSelect.options[i].remove();
        }
        axios
            .post(`${config.url}/${lng}/getWarehouses`, selectCity)
            .then((data) => {
                const warehouses = data.data.warehousesRes;
                const select = document.querySelector(".selectWarehouse");
                const option = document.createElement("option");
                option.value = "";
                $(".selectWarehouse").select2({
                    placeholder: `${i18next.t("text review form")}`,
                    sorter: function (results) {
                        var query = $(".select2-search__field")
                            .val()
                            .toLowerCase();
                        return results.sort(function (a, b) {
                            return (
                                a.text.toLowerCase().indexOf(query) -
                                b.text.toLowerCase().indexOf(query)
                            );
                        });
                    },
                });
                select.appendChild(option);

                if (lng === "ua") {
                    warehouses.forEach((el) => {
                        const select =
                            document.querySelector(".selectWarehouse");
                        const option = document.createElement("option");
                        option.value = el.Description;
                        option.textContent = el.Description;
                        select.appendChild(option);
                    });
                }
                if (lng === "ru") {
                    warehouses.forEach((el) => {
                        const select =
                            document.querySelector(".selectWarehouse");
                        const option = document.createElement("option");
                        option.value = el.DescriptionRu;
                        option.textContent = el.DescriptionRu;
                        select.appendChild(option);
                    });
                }
                preloader.remove();
            });
    });
}

const sel2 = document.querySelector(".js-example-basic-multiple");
if (sel2) {
    $(document).ready(function () {
        $(".js-example-basic-multiple").select2({
            placeholder: "",
        });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************

const sumCart = () => {
    const cntThisProductInCart = document.querySelectorAll(
        ".cntThisProductInCart"
    );
    const priceProductInCart = document.querySelectorAll(".priceProductInCart");

    const discountPromotionProduct = document.querySelectorAll(
        ".discountPromotionProduct"
    );

    const cntThisGiftProductInCart = document.querySelectorAll(
        ".cntThisGiftProductInCart"
    );

    let discount = 0;
    let sumInCart = 0;

    for (let i = 0; i < priceProductInCart.length; i++) {
        sumInCart =
            sumInCart +
            priceProductInCart[i].innerHTML * 1 * cntThisProductInCart[i].value;
    }
    if (discountPromotionProduct.length > 0) {
        for (let i = 0; i < discountPromotionProduct.length; i++) {
            discount =
                discount +
                discountPromotionProduct[i].innerHTML *
                    1 *
                    cntThisGiftProductInCart[i].innerHTML *
                    1;
        }
        discountInCartValue.innerHTML = "₴ -" + discount + ".00";
    }

    if (discount === 0 && discountInCartValue) {
        discountInCartValue.remove();
    }

    if (sumInCart === 0) {
        orderBtn.disabled = true;
        window.location.href = `${config.url}/${lng}`;
    }

    sumInCartValue.innerHTML = "₴ " + sumInCart + ".00";
    sumInCartForPayValue.innerHTML = "₴ " + sumInCart + ".00";

    const avaliableBalanceFromReferalProgramment = document.querySelector(
        ".avaliableBalanceFromReferalProgram"
    );

    let maxDiscountFromBonuce = 0;

    if (avaliableBalanceFromReferalProgramment) {
        // Извлекаем текстовое содержимое
        const text = avaliableBalanceFromReferalProgramment.textContent;

        // Извлекаем значение после "Бонус:"
        const match = text.match(/Бонус:\s*([\d.]+)\s*б\./);

        if (match) {
            const bonuce = parseFloat(match[1]);

            maxDiscountFromBonuce =
                bonuce > Math.ceil(sumInCart / 2)
                    ? Math.ceil(sumInCart / 2)
                    : bonuce;
        }
    }

    // Проверяем, найден ли элемент
    if (rangeInput) {
        // Устанавливаем новое значение для атрибута max
        rangeInput.max = maxDiscountFromBonuce;
    }

    if (numberInput && rangeInput) {
        // Обновление range при изменении значения в number
        numberInput.addEventListener("input", () => {
            let value = parseFloat(numberInput.value);

            // Убедимся, что значение не выходит за пределы min и max range
            value = Math.max(
                parseFloat(rangeInput.min),
                Math.min(value, parseFloat(rangeInput.max))
            );

            // Устанавливаем значение range
            rangeInput.value = value;
        });

        // Обновление number при изменении значения в range
        rangeInput.addEventListener("input", () => {
            // Устанавливаем значение number
            numberInput.value = rangeInput.value;
            sumInCartForPayValue.innerHTML =
                "₴ " + (sumInCart - rangeInput.value) + ".00";
        });
    }
};

if (removeFromCart.length > 0) {
    removeFromCart.forEach((removeElement) => {
        removeElement.addEventListener("click", (e) => {
            e.preventDefault();
            let parent = removeElement.closest(".productsCart");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);

            if (rangeInput) {
                rangeInput.value = 0;
                numberInput.value = 0;
            }

            const removeFromCart = {
                idProduct: removeElement.dataset.productId,
            };
            axios
                .post(
                    `${config.url}/${lng}/remove-product-from-cart`,
                    removeFromCart
                )
                .then((data) => {
                    if (data) {
                        let parent = removeElement.closest(".productsCart");
                        parent.remove();
                        sumCart();
                        cntProductsCart.innerHTML = data.data.cart.length;
                    }
                });
        });
    });
}

if (sumInCartValue) {
    sumCart();
    const cntThisProductInCart = document.querySelectorAll(
        ".cntThisProductInCart"
    );
    cntThisProductInCart.forEach((cntInput) => {
        cntInput.addEventListener("change", () => {
            let parent = cntInput.closest(".productsCart");
            preloader.classList.add("active");
            parent.insertAdjacentElement("beforeend", preloader);
            if (rangeInput) {
                rangeInput.value = 0;
                numberInput.value = 0;
            }

            // sumInCartForPayValue.innerHTML = "₴ " + sumInCart + ".00";

            const parentCntElement = cntInput.closest(".productsCart");

            const changeProductInCart = {
                idProduct: cntInput.dataset.productId,
                cntProduct: cntInput.value * 1,
            };

            if (parentCntElement.querySelector(".cntThisGiftProductInCart")) {
                parentCntElement.querySelector(
                    ".cntThisGiftProductInCart"
                ).innerHTML = changeProductInCart.cntProduct;
            }

            axios
                .post(
                    `${config.url}/${lng}/change-to-cart`,
                    changeProductInCart
                )
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                    sumCart();
                });
        });
    });
}

if (forWhom) {
    forWhom.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
        });
    });
}

if (paginationActive.length > 0) {
    let params = new URLSearchParams(document.location.search);

    let page = params.get("page") ?? 1;

    if (parseInt(page) === 1) {
        paginationActiveFirst.classList.add("active");
    }
    if (parseInt(page) !== 1) {
        paginationActive.forEach((el) => {
            if (el.childNodes[0].innerHTML === page) {
                el.classList.add("active");
            }
        });
    }
}

if (addToCard.length > 0) {
    addToCard.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
            const cntProducts = document.querySelector(".cntProducts");
            let valueCntProduct = 1;

            if (cntProducts) {
                valueCntProduct = cntProducts.value * 1;
            }
            const product = {
                idProduct: el.dataset.productId,
                valueCntProduct,
            };
            axios
                .post(`${config.url}/${lng}/add-to-cart`, product)
                .then((data) => {
                    cntProductsCart.innerHTML = data.data.cntProducts;
                    if (data.data.productInCart)
                        window.location.href = `${config.url}/${lng}/checkout`;
                });
            el.classList.add("added");
        });
    });
}

if (removePhoto.length > 0) {
    removePhoto.forEach((photo) => {
        photo.addEventListener("click", (e) => {
            e.preventDefault();

            photo.disabled = true;

            preloader.classList.add("active");
            photo.insertAdjacentElement("afterbegin", preloader);

            const photoData = {
                imgName: photo.dataset.imgName,
                albumId: photo.dataset.albumId,
            };

            axios
                .patch(`${config.url}/admin/remove-photo`, photoData)
                .then((data) => {
                    if (data) {
                        preloader.remove();
                    }
                });
        });
    });
}

const paymentOrder = document.querySelector(".payment-order");
if (paymentOrder) {
    paymentOrder.addEventListener("click", (e) => {
        e.preventDefault();
        const numberOrder = document.querySelector(".number-order");
        const order = numberOrder.textContent.split(": ")[1];
        const data = {
            order: order,
        };
        axios.post(`${config.url}/${lng}/pay-to-order`, data).then((result) => {
            const form = result.data.html;
            document
                .querySelector("body")
                .insertAdjacentHTML("beforebegin", form);

            const liqPayForm = document.querySelector("#liqpayForm");
            liqPayForm.submit();
        });
    });
}

// ***************************************************************************************************************
// ***************************************************************************************************************
// ***************************************************************************************************************
// Create reviews for products

const createReviewForProduct = document.querySelector(
    ".createReviewForProduct"
);
if (createReviewForProduct) {
    createReviewForProduct.addEventListener("click", (e) => {
        e.preventDefault();
        const productId = document
            .querySelector(".createReviewForProduct")
            .getAttribute("data-product-id");
        const authorName = document.querySelector(".authorName");
        const rating = document.querySelector(".rating");
        const review = document.querySelector(".review");
        const reviewAdd = document.querySelector(".reviewAdd");
        removeErrors();

        const data = {
            productId: productId,
            authorName: authorName.value,
            rating: rating.value,
            review: review.value,
        };

        axios
            .post(`${config.url}/${lng}/create-review-for-product`, data)
            .then((result) => {
                authorName.value = "";
                rating.value = "";
                review.value = "";
                createReviewForProduct.disabled = true;
                alertSuccess.style.display = "block";
            })
            .catch((error) => {
                if (!error.response.data.validationErrors) {
                    const err = `<p class="error-massage-global">* ${i18next.t(
                        "global error"
                    )}</p>`;
                    reviewAdd.insertAdjacentHTML("afterend", err);
                } else {
                    const errors = error.response.data.validationErrors;

                    errors.forEach((err) => {
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">* ${i18next.t(
                            err.msg
                        )}</p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                        orderBtn.disabled = false;
                    });
                }
            });
    });
}

const filterId = document.querySelector(".filter-id");

if (filterId) {
    filterId.addEventListener("change", () => {
        if (filterId.value !== "Все") {
            window.location.href = `${config.url}/admin/admin-get-orders?orderStatus=${filterId.value}`;
        } else {
            window.location.href = `${config.url}/admin/admin-get-orders`;
        }
    });
}

// turnover for the period

const btnCheckPeriod = document.querySelector(".btn-check-period");

if (btnCheckPeriod) {
    btnCheckPeriod.addEventListener("click", (e) => {
        e.preventDefault();

        const calendarStart = document.querySelector(".calender-start");
        const calendarEnd = document.querySelector(".calender-end");
        const addTurnoverForPeriod = document.querySelector(
            ".add-turnoverForPeriod"
        );
        const turnoverBlock = document.querySelector(".turnover-block");

        const startDate = calendarStart.value;
        const endDate = calendarEnd.value;

        const data = {
            startDate: startDate,
            endDate: endDate,
        };

        axios
            .post(`${config.url}/admin/check-turnover-for-period`, data)
            .then((result) => {
                const totalAmount = result.data.resultData.totalAmount;
                const count = result.data.resultData.count;
                if (turnoverBlock) {
                    turnoverBlock.remove();
                }
                addTurnoverForPeriod.insertAdjacentHTML(
                    "afterend",
                    `<label class="form-label turnover-block">Товарооборот: ${totalAmount}.00, Количество заказов: ${count}</label>`
                );
            });
    });
}

const noticePk = document.querySelector(".notice-pk");

if (noticePk) {
    noticePk.addEventListener("click", (e) => {
        e.preventDefault();
    });
}

if (btnSignupUser) {
    btnSignupUser.addEventListener("click", (e) => {
        e.preventDefault();

        btnSignupUser.disabled = true;

        const formFirstName = document.getElementById("formFirstName");
        const formLastName = document.getElementById("formLastName");
        const formEmail = document.getElementById("formEmail");
        const formPassword = document.getElementById("formPassword");
        const formConfirmPassword = document.getElementById(
            "formConfirmPassword"
        );

        removeErrors();

        axios
            .post(`${config.url}/${lng}/personal-office`, {
                firstName: formFirstName.value,
                lastName: formLastName.value,
                email: formEmail.value,
                password: formPassword.value,
                confirmPassword: formConfirmPassword.value,
            })
            .then((res) => {
                if (res.status === 201) {
                    window.location.href = `${config.url}/${lng}/personal-office`;
                }
            })
            .catch((error) => {
                console.log(error);
                if (!error.response.data.validationErrors) {
                    const err = `<p class="error-massage-global">
                                        * ${i18next.t("global error")}
                                </p>`;
                    btnSignupUser.insertAdjacentHTML("afterend", err);
                } else {
                    removeErrors();

                    const errorsValidate = error.response.data.validationErrors;

                    errorsValidate.forEach((err) => {
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">
                                            * ${i18next.t(err.msg)}
                                       </p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                    });
                    btnSignupUser.disabled = false;
                }
            });
    });
}

if (btnLoginUser) {
    btnLoginUser.addEventListener("click", (e) => {
        e.preventDefault();
        btnLoginUser.disabled = true;

        const formEmail = document.getElementById("emailForLogin");
        const formPassword = document.getElementById("passwordForLogin");

        removeErrors();
        axios
            .post(`${config.url}/${lng}/login`, {
                email: formEmail.value,
                password: formPassword.value,
            })
            .then((res) => {
                if (res.status === 201) {
                    window.location.href = `${config.url}/${lng}/personal-office`;
                }
            })
            .catch((error) => {
                const err = `<p class="error-massage-global">
                                        * ${i18next.t(
                                            error.response.data.errorMessage
                                        )}
                                </p>`;
                btnLoginUser.insertAdjacentHTML("afterend", err);

                btnLoginUser.disabled = false;
            });
    });
}

if (btnEditUserDataPrivateOffice) {
    btnEditUserDataPrivateOffice.addEventListener("click", (e) => {
        e.preventDefault();
        btnEditUserDataPrivateOffice.disabled = true;

        const firstName = document.getElementById("firstName");
        const lastName = document.getElementById("lastName");
        const email = document.getElementById("email");
        const password = document.getElementById("password");
        const confirmPassword = document.getElementById("confirmPassword");

        const phoneNumber = document.getElementById("phoneNumber");

        const form = document.querySelector("form");
        const formData = new FormData(form);

        const imageUser = document.querySelector("#imageUser");
        formData.append("imageUser", imageUser);

        axios
            .post(`${config.url}/${lng}/update-user`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                if (res.status === 201) {
                    window.location.href = `${config.url}/${lng}/personal-office`;
                }
            })
            .catch((error) => {
                if (!error.response.data.validationErrors) {
                    const err = `<p class="error-massage-global">
                                        * ${i18next.t("global error")}
                                </p>`;
                    btnSignupUser.insertAdjacentHTML("afterend", err);
                } else {
                    removeErrors();

                    const errorsValidate = error.response.data.validationErrors;

                    errorsValidate.forEach((err) => {
                        const inputForm = document.getElementsByName(err.param);
                        inputForm[0].classList.add("validateForm");

                        const el = `<p class="error-massage">
                                            * ${i18next.t(err.msg)}
                                       </p>`;

                        inputForm[0].parentElement.insertAdjacentHTML(
                            "beforebegin",
                            el
                        );
                    });
                    btnEditUserDataPrivateOffice.disabled = false;
                }
            });
    });
}

const btnDeleteConfirm = document.querySelector(".btnDeleteConfirm");

if (btnDeleteConfirm) {
    btnDeleteConfirm.addEventListener("click", (e) => {
        e.preventDefault();
        preloader.classList.add("active");
        axios
            .delete(`${config.url}/${lng}/delete-account`, {})
            .then((res) => {
                if (res.status === 201) {
                    window.location.href = `${config.url}/${lng}`;
                }
            })
            .catch((error) => {
                const err = `<p class="error-massage-global">
                                        * ${i18next.t(
                                            error.response.data.errorMessage
                                        )}
                                </p>`;
                btnLoginUser.insertAdjacentHTML("afterend", err);
            });
    });
}

const sortable = document.querySelectorAll(".sortable");

if (sortable.length > 0) {
    document.querySelectorAll(".sortable").forEach((node) => {
        new Sortable(node, {
            group: "scoreboard",
            direction: "vertical",
            animation: 250,
            scroll: true,
            bubbleScroll: true,
            onMove: function (evt, originalEvent) {
                if (
                    evt.dragged.classList.contains("group") &&
                    evt.to.classList.contains("group__goals")
                ) {
                    return false;
                }
            },
        });
    });
}

let items = document.querySelectorAll(".sort-li-item");

if (items.length > 0) {
    items.forEach((item) => {
        item.addEventListener("dragend", (event) => {
            ondragend = (event) => {
                preloader.classList.add("active");
                const bannersID = [];
                const bannerBtn = document.querySelectorAll(".bannerBtn");
                for (let i = 0; i < bannerBtn.length; i++) {
                    bannersID.push(bannerBtn[i].dataset.id);
                }

                const banners = {
                    bannersID: bannersID,
                };
                axios
                    .patch(`/admin/updateBannersSerialNumber`, banners)
                    .then((data) => {
                        if (data) {
                            preloader.remove();
                        }
                    });
            };
        });
    });
}

const delBanner = document.querySelectorAll(".delBanner");

if (delBanner.length > 0) {
    delBanner.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            e.preventDefault();
            preloader.classList.add("active");
            axios
                .patch(`/admin/deleteBanner`, { data: btn.dataset.id })
                .then((data) => {
                    if (data) {
                        if (btn.classList.contains("delElementBtn")) {
                            btn.closest(".bannerBlock").remove();
                        }
                        preloader.remove();
                    }
                });
        });
    });
}

// Referral link

const shareReferralLink = document.getElementById("referralCopyBnt");

if (shareReferralLink) {
    shareReferralLink.addEventListener("click", (e) => {
        e.preventDefault();
        // Get the text field
        const copyText = document.getElementById("referralLink");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
    });
}
